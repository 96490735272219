// NPM libraries
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { QRCode } from 'react-qr-svg';

// Semantic UI Components
import {
  Container,
  Dropdown,
  Form,
  Image,
  Message,
  Segment,
  Step,
} from 'semantic-ui-react';

class Homepage extends React.Component {

  constructor() {
    super();

    this.state = {
      countries: [],
      phones: [],
      lastUpdated: '',
      name: '',
      nameError: false,
      email: '',
      emailError: false,
      code: '',
      phone: '',
      phoneError: false,
      country: '',
      countryError: false,
      company: '',
      companyError: false,
      role: '',
      roleError: false,
      usesAWS: false,
      message: '',
      formError: false,
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  
  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }
  
  async handleSubmit() {
    const API_URL = 'https://qt57s58zgj.execute-api.us-east-1.amazonaws.com/dev';
    const { email, name, code, phone, country, company, role, usesAWS, message } = this.state;
    this.setState({ formError: false });
    const response = await axios({
        method: 'post',
        url: `${API_URL}/user`,
        data: {
          email,
          name,
          phone: `${code} ${phone}`,
          country,
          company,
          role,
          usesAWS,
          message,
        },
        validateStatus: status => {
          return status <= 500;
        },
      })
      .catch(console.error);
    if (response.status >= 200 && response.status < 300) {
      this.props.userStateHandler({ user: response.data });
    } else {
      console.log(response.data.message);
      this.setState({ formError: response.data.message });
    }
  }
  
  componentDidMount() {
    fetch('/countries.json')
      .then(response => response.json())
      .catch(console.error)
      .then(countries => {
        this.setState({ countries: countries });
      })
      .catch(console.error);
    fetch('/phones.json')
      .then(response => response.json())
      .catch(console.error)
      .then(phones => {
        this.setState({ phones: phones });
      })
      .catch(console.error);
    fetch('/meta.json')
      .then(response => response.json())
      .catch(console.error)
      .then(meta => {
        this.setState({ lastUpdated: meta.lastUpdated })
      })
      .catch(console.error);

  }
  
  render() {
    const { user } = this.props;
    const {
      countries,
      phones,
      lastUpdated,
      name,
      nameError,
      email,
      emailError,
      code,
      phone,
      phoneError,
      country,
      countryError,
      company,
      companyError,
      role,
      roleError,
      usesAWS,
      message,
      formError,
    } = this.state;
    const lastUpdatedMessage = (
      <Message
        compact
        attached='bottom'
        size='small'
        content={`Última actualización: ${lastUpdated} (v${global.appVersion})`}
      />
    );
    const title = 'Escala 24x7 - Rifacloud';

    if (user) {
      return (
        <Container
          style={{
            margin: '7.5em 0',
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>{title}</h2>
          <h1>¡Participa y gana con Escala 24x7!</h1>
          <Image
            src='/homepage.png'
            style={{
              margin: '10px auto',
              width: '67%',
              maxWidth: '600px',
            }}
          />
          {lastUpdatedMessage}
          <Message
            success
            content={`¡Ya estás participando en la rifa, ${user.name}!`}
          />
          <p>
            Para invitar a tus amigos a participar,
            comparte este código QR con todos
          </p>
          <QRCode
            value={window.location.origin}
            style={{
              width: '67%',
              maxWidth: '256px'
            }}
          />
        </Container>
      );
    } else {
      return (
        <Container
          style={{
            margin: '7.5em 0',
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>{title}</h2>
          <h1>¡Participa y gana con Escala 24x7!</h1>
          <Image
            src='/homepage.png'
            style={{
              margin: '10px auto',
              width: '67%',
              maxWidth: '600px',
            }}
          />
          {lastUpdatedMessage}
          <Step.Group ordered fluid attached='top'>
            <Step
              active={
                !name ||
                !email ||
                !code ||
                !phone ||
                !country ||
                !company ||
                !role
              }
            >
              <Step.Content>
                <Step.Title>Ingresa tus datos</Step.Title>
              </Step.Content>
            </Step>
            <Step
              active={
                !!name &&
                !!email &&
                !!code &&
                !!phone &&
                !!country &&
                !!company &&
                !!role
              }
            >
              <Step.Content>
                <Step.Title>Haz click en enviar</Step.Title>
              </Step.Content>
            </Step>
            <Step>
              <Step.Content>
                <Step.Title>¡Participa!</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
          <Segment attached='bottom'>
            <Form error onSubmit={this.handleSubmit}>
              <Form.Input
                required
                placeholder='Tu nombre completo'
                name='name'
                value={name}
                error={nameError}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                placeholder='Tu correo electrónico corporativo'
                name='email'
                value={email}
                error={emailError}
                onChange={this.handleChange}
              />
              <Form.Group
                widths='equal'
              >
                <Form.Field
                  required
                  control={Dropdown}
                  search
                  selection
                  placeholder='Tu código de país'
                  name='code'
                  value={code}
                  options={phones}
                  onChange={this.handleChange}
                />
                <Form.Input
                  required
                  type='number'
                  placeholder='Tu número de teléfono'
                  name='phone'
                  value={phone}
                  error={phoneError}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group
                widths='equal'
              >
                <Form.Field
                  required
                  control={Dropdown}
                  selection
                  search
                  placeholder='Tu país'
                  name='country'
                  options={countries}
                  value={country}
                  error={countryError}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Input
                required
                placeholder='Tu compañía'
                name='company'
                value={company}
                error={companyError}
                onChange={this.handleChange}
              />
              <Form.Input
                required
                placeholder='Tu cargo'
                name='role'
                value={role}
                error={roleError}
                onChange={this.handleChange}
              />
              <Form.TextArea
                placeholder='Tu mensaje (opcional)'
                name='message'
                value={message}
                onChange={this.handleChange}
              />
              <Form.Field>¿Actualmente usas AWS?</Form.Field>
              <Form.Group
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '1em',
                }}
              >
                <Form.Radio
                  label='Sí'
                  name='usesAWS'
                  checked={usesAWS}
                  onChange={() => this.handleChange(null, { name: 'usesAWS', value: true })}
                />
                <Form.Radio
                  label='No'
                  name='usesAWS'
                  checked={!usesAWS}
                  onChange={() => this.handleChange(null, { name: 'usesAWS', value: false })}
                />
              </Form.Group>
              <Message
                error
                hidden={!formError}
                header='Error'
                content={formError}
              />
              <Form.Button
                type='submit'
                content='Enviar'
                style={{
                  backgroundColor:'#2e757b',
                  /*backgroundColor: '#49b2ab',*/
                  color: 'white',
                }}
                disabled={
                  !name ||
                  !email ||
                  !code ||
                  !phone ||
                  !country ||
                  !company ||
                  !role
                }
              />
            </Form>
          </Segment>
        </Container>
      );
    }
  }

}

Homepage.propTypes = {
  userStateHandler: PropTypes.func,
  user: PropTypes.object,
};

Homepage.defaultProps = {
  userStateHandler: ({ user }) => {
    this.props.user = user;
  },
  user: {
    name: 'Tu nombre completo',
    email: 'Tu correo electrónico corporativo',
    phone: 'Tu número de teléfono',
    country: 'Tu país',
    company: 'Tu compañía',
    role: 'Tu cargo',
    usesAWS: false,
    message: 'Tu mensaje (opcional)',
  },
};

export default Homepage;
