// NPM libraries
import React from 'react';
import {
  Route,
  Switch,
  Link,
} from 'react-router-dom';

// Semantic UI Components
import {
  Image,
  Menu,
} from 'semantic-ui-react';

// Components
import Homepage from '../Homepage';
import Login from '../Login';
import NotFound from '../Errors/NotFound';
import Raffle from '../Raffle';
import Users from '../Users';

class App extends React.Component {

  constructor() {
    super();

    this.state = {
      admin: null,
      auth: false,
      user: null,
      visible: false,
    };

    this.authStateHandler = this.authStateHandler.bind(this);
    this.userStateHandler = this.userStateHandler.bind(this);

  }

  authStateHandler({ auth, admin }) {
    this.setState({
      auth,
      admin,
    });
    localStorage.setItem('auth', JSON.stringify(auth));
    localStorage.setItem('admin', JSON.stringify(admin));
  }

  userStateHandler({ user }) {
    this.setState({
      user,
    });
    localStorage.setItem('user', JSON.stringify(user));
  }

  toggleSidebar() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  closeSidebar() {
    this.state.visible && this.setState({
      visible: false,
    });
  }
  
  componentDidMount() {
    const auth = localStorage.getItem('auth');
    const admin = localStorage.getItem('admin');
    const user = localStorage.getItem('user');
    this.setState({
      auth: auth && JSON.parse(auth),
      admin: admin && JSON.parse(admin),
      user: user && JSON.parse(user),
    });
  }

  render() {
    const { auth, admin, user } = this.state;
    return (
      <div>
        <Menu fixed='top' inverted borderless style={{zIndex: 103}}>
          <Menu.Item header>
            <Link to='/'>
              <Image size='tiny' src='Logo-Escala-24x7-Fondo-Negro-600x400px.png' />
            </Link>
          </Menu.Item>
          <Menu.Menu position='right'>
            <Login authStateHandler={this.authStateHandler} auth={auth} admin={admin}/>
          </Menu.Menu>
        </Menu>
        <div id="app-content" style={{minHeight: '100vh', minWidth: '100vw', overflow: 'auto'}}>
          {/*Add app static routes below*/}
          <Switch>
            <Route exact path="/" render={() => <Homepage userStateHandler={this.userStateHandler} user={user}/>}/>
            <Route exact path="/users" render={() => <Users authStateHandler={this.authStateHandler} auth={auth} admin={admin}/>}/>
            <Route exact path="/raffle" render={() => <Raffle authStateHandler={this.authStateHandler} auth={auth} admin={admin}/>}/>
            {/*Default catch all route*/}
            <Route component={NotFound}/>
          </Switch>
        </div>
        <Menu fixed='bottom' stackable inverted borderless style={{zIndex: 103}}>
          <Menu.Item style={{flex: 1, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <a href='https://escala24x7.com' target='_blank' rel='noopener noreferrer' style={{color: '#ffffff'}}>Hecho con &#10084; por Escala 24x7</a>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default App;
