/* eslint-disable jsx-a11y/anchor-is-valid */

// NPM libraries
import React from 'react';
import { withRouter } from 'react-router-dom';

// Semantic UI Components
import {
  Container,
  Header,
} from 'semantic-ui-react';

const Forbidden = () => (
  <div>
    <Container text textAlign='center' style={{padding: '7.5em 0 0'}}>
      <Header as='h1'>403</Header>
      <h3>Forbidden</h3>
      <p>Sorry, you're not allowed to view this page.</p>
    </Container>
  </div>
);

export default withRouter(Forbidden);
