/* eslint-disable jsx-a11y/anchor-is-valid */

// NPM libraries
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Semantic UI Components
import {
  Button,
  Container,
  Dimmer,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react';

// Components
import Forbidden from '../Errors/Forbidden';
import ConfettiShower from '../ConfettiShower';

class Raffle extends React.Component {

  constructor() {
    super();

    this.state = {
      winner: null,
      loading: false,
      error: null,
      isConfettiActive: false,
    };

    this.handleRaffle = this.handleRaffle.bind(this);

  }

  async getWinner(jwt) {
    const API_URL = 'https://qt57s58zgj.execute-api.us-east-1.amazonaws.com/dev';
    const response = await axios({
      method: 'get',
      url: `${API_URL}/winner`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      validateStatus: status => {
        return status <= 500;
      },
    })
      .catch(error => {
        console.error(error);
        this.props.authStateHandler({ auth: false, admin: null });
      });
    if (response) {
      if (response.status >= 200 && response.status < 300 && response.data.name) {
        if (this.state.error) this.setState({ error: null });
        this.setState({
          winner: response.data.name,
          isConfettiActive: true,
        });
        setTimeout(() => this.setState({ isConfettiActive: false }), 5000);
      } else {
        this.setState({
          error: (<Message negative header="Ha ocurrido un error" content={response.data.message}/>),
        });
      }
    }
  }

  async handleRaffle() {
    this.setState({
      loading: true,
    });
    const { auth, admin } = this.props;
    if (auth && admin && admin.jwt) {
      await this.getWinner(admin.jwt);
      this.setState({
        loading: false,
      });
    }
  }

  content() {
    const { loading, error, winner } = this.state;
    if (loading) {
      return (
        <Dimmer active>
          <Loader>Cargando</Loader>
        </Dimmer>
      );
    } else if (error) {
      return error;
    } else if (winner) {
      return (
        <Header
          style={{
            textTransform: 'capitalize',
          }}
          size="huge"
          content={`¡${winner.trim().toLowerCase()}!`}
        />
      );
    } else {
      return (
        <Message content="¡Todavía no has elegido a un ganador!"/>
      );
    }
  }

  render() {
    const { auth, admin } = this.props;

    if (auth && admin && admin.jwt) {
      return (
        <Container
          style={{
            margin: '7.5em 0',
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>Rifa</h2>
          <h3>El ganador es:</h3>
          {this.state.isConfettiActive && <ConfettiShower />}
          {this.content()}
          <Button onClick={this.handleRaffle}>Elige un ganador</Button>
        </Container>
      );
    } else {
      return (
        <Forbidden/>
      );
    }
  }

}

Raffle.propTypes = {
  authStateHandler: PropTypes.func,
  auth: PropTypes.bool,
  admin: PropTypes.object,
};

Raffle.defaultProps = {
  authStateHandler: ({ auth, admin }) => {
    this.props.auth = auth;
    this.props.admin = admin;
  },
  auth: false,
  admin: null,
};

export default Raffle;
