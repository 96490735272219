import React from 'react';
import Confetti from 'react-confetti';

class ConfettiShower extends React.PureComponent {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <Confetti numberOfPieces={100} recycle={false} gravity={0.5} />
        <audio ref={this.audioRef} src="/kudos_horn.mp3" autoPlay />
      </React.Fragment>
    );
  }
}

export default ConfettiShower;
