/* eslint-disable jsx-a11y/anchor-is-valid */

// NPM libraries
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

// Semantic UI Components
import {
  Dropdown,
  Form,
  Icon,
  Message,
} from 'semantic-ui-react';

// Custom libraries
// import serialize from '../../utils';

class Login extends React.Component {

  constructor() {
    super();

    this.state = {
      visible: false,
      username: '',
      password: '',
      error: false,
      open: false,
    };

    this.toggleLogin = this.toggleLogin.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleEmailLogin = this.handleEmailLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

  }

  toggleLogin(e) {
    if (e) e.stopPropagation();
    this.setState({
      visible: false,
    });
  }

  handleChange(e, { name, value }) {
    this.setState({
      [name]: value,
    });
  }

  async handleEmailLogin(e) {
    const API_URL = 'https://qt57s58zgj.execute-api.us-east-1.amazonaws.com/dev';
    const { username, password } = this.state;
    this.setState({ error: false });
    const response = await axios({
        method: 'post',
        url: `${API_URL}/admin/login`,
        data: {
          username,
          password,
        },
        validateStatus: status => {
          return status <= 500;
        },
      })
      .catch(console.error);
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        this.props.authStateHandler({ auth: true, admin: response.data });
        this.setState({ open: false });
      } else {
        console.log(response.data.message);
        this.setState({ error: response.data.message });
      }
    } else {
      this.setState({ error: 'Internal server error' });
    }
  }

  handleLogout() {
    this.props.authStateHandler({ auth: false, admin: null });
    this.setState({ open: false });
  }

  handleAuthStateChanged() {
  }

  componentDidMount() {
    this.handleAuthStateChanged();
  }

  render() {

    const { auth } = this.props;
    const { username, password, error, open } = this.state;
    const trigger = (auth &&
      <span>
        <Icon name='user' />Cuenta
      </span>
    );

    if (auth) {
      return (
        <Dropdown
          item
          trigger={trigger}
          open={open}
          onClick={() => this.setState({ open: !open })}
          closeOnChange={false}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              icon='users'
              text='Participantes'
              as={Link}
              to='/users'
              onClick={() => this.setState({ open: !open })}
            />
            <Dropdown.Item
              icon='random'
              text='Rifa'
              as={Link}
              to='/raffle'
              onClick={() => this.setState({ open: !open })}
            />
            <Dropdown.Item
              icon='sign out'
              text='Salir'
              onClick={this.handleLogout}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown
          item
          text='Iniciar sesión'
          open={open}
          onClick={() => this.setState({ open: !open })}
          closeOnChange={false}
        >
          <Dropdown.Menu>
            <Form
              error
              onSubmit={this.handleEmailLogin}
              style={{minWidth: '15rem', margin: '11px 11px'}}
              hidden={this.state.visible}
            >
              <Form.Input
                icon='user'
                iconPosition='left'
                type='text'
                name='username'
                placeholder='Usuario'
                value={username}
                onClick={e => e.stopPropagation()}
                onBlur={e => e.stopPropagation()}
                onChange={this.handleChange}
              />
              <Form.Input
                icon='lock'
                iconPosition='left'
                type='password'
                name='password'
                placeholder='Contraseña'
                value={password}
                onClick={e => e.stopPropagation()}
                onBlur={e => e.stopPropagation()}
                onChange={this.handleChange}
              />
              <Message
                error
                hidden={!error}
                header='Error'
                content={error}
              />
              <Form.Button
                fluid
                onBlur={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
                disabled={
                  !username ||
                  !password
                }
              >
                <Icon name='sign in'/>Enviar
              </Form.Button>
            </Form>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }
}

Login.propTypes = {
  authStateHandler: PropTypes.func,
  auth: PropTypes.bool,
  admin: PropTypes.object,
};

Login.defaultProps = {
  authStateHandler: ({ auth, admin }) => {
    this.props.auth = auth;
    this.props.admin = admin;
  },
  auth: false,
  admin: null,
};

export default Login;
