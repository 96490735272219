/* eslint-disable jsx-a11y/anchor-is-valid */

// NPM libraries
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import { Parser } from 'json2csv';

// Semantic UI Components
import {
  Button,
  Container,
  Dimmer,
  Icon,
  Input,
  Loader,
  Message,
  Rating,
  Segment,
  Table,
} from 'semantic-ui-react';

// Components
import Forbidden from '../Errors/Forbidden';
import Unauthorized from '../Errors/Unauthorized';

class Users extends React.Component {

  constructor() {
    super();

    this.state = {
      users: [],
      results: [],
      filter: '',
      loading: false,
      error: (<Forbidden/>),
      column: null,
      direction: null,
    };

    this.handleExport = this.handleExport.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getUsers = this.getUsers.bind(this);

  }

  handleExport() {
    const { results } = this.state;

    if (!results.length) {
      console.error('No data to export');
      return;
    }

    const parser = new Parser({
      fields: [
        'starred',
        'name',
        'email',
        'phone',
        'country',
        'company',
        'role',
        'message',
        'usesAWS',
        'hasWon',
        'createdAt',
        'updatedAt',
      ],
    });
    const csv = parser.parse(results);

    const blob = new Blob([ csv ], { type: 'text/csv' });
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');

    a.download = 'export.csv';
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = [ 'text/json', a.download, a.href ].join(':');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }

  handleFilter(e, { value }) {
    const { users } = this.state;

    this.setState({
      results: !value
        ? users
        : users.filter(user => {
          return (
            (user.name && user.name.toLowerCase().includes(value.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(value.toLowerCase())) ||
            (user.phone && user.phone.toLowerCase().includes(value.toLowerCase())) ||
            (user.country && user.country.toLowerCase().includes(value.toLowerCase())) ||
            (user.company && user.company.toLowerCase().includes(value.toLowerCase())) ||
            (user.role && user.role.toLowerCase().includes(value.toLowerCase())) ||
            (user.message && user.message.toLowerCase().includes(value.toLowerCase())) ||
            (user.usesAWS ? 'Sí' : 'No').toLowerCase().includes(value.toLowerCase()) ||
            (user.hasWon ? 'Sí' : 'No').toLowerCase().includes(value.toLowerCase()) ||
            (user.createdAt && user.createdAt.toLowerCase().includes(value.toLowerCase())) ||
            (user.udpatedAt && user.updatedAt.toLowerCase().includes(value.toLowerCase()))
          );
        }),
      filter: value,
    });
  }

  handleSort(clickedColumn) {
    const { users, column, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        results: _.sortBy(users, [ clickedColumn ]),
        direction: 'ascending',
      });
      return;
    }

    this.setState({
      column: clickedColumn,
      results: users.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  async handleRate(e, index, starred) {
    const API_URL = 'https://qt57s58zgj.execute-api.us-east-1.amazonaws.com/dev';
    let { users, results } = this.state;
    const { auth, admin } = this.props;
    if (auth && admin && admin.jwt) {
      const response = await axios({
        method: 'patch',
        url: `${API_URL}/user`,
        headers: {
          Authorization: `Bearer ${admin.jwt}`,
        },
        data: {
          email: results[index].email,
          starred,
        },
        validateStatus: status => {
          return status <= 500;
        },
      })
        .catch(error => {
          console.error(error);
          this.props.authStateHandler({ auth: false, admin: null });
        });
      if (response) {
        if (response.status >= 200 && response.status < 300) {
          users[index].starred = response.data.starred;
          results[index].starred = response.data.starred;
          this.setState({
            users,
            results,
          });
        } else {
          console.log(response.data.message);
        }
      }
    }
  }

  async getUsers(jwt) {
    const API_URL = 'https://qt57s58zgj.execute-api.us-east-1.amazonaws.com/dev';
    const response = await axios({
      method: 'get',
      url: `${API_URL}/user`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      validateStatus: status => {
        return status <= 500;
      },
    })
      .catch(error => {
        console.error(error);
        this.props.authStateHandler({ auth: false, admin: null });
      });
    if (response) {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        console.log(response.data.message);
        this.setState({
          error: (<Unauthorized/>),
        });
      }
    }
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.auth !== this.props.auth || nextState.users.length !== this.state.users.length) {
      const { users } = this.state;
      if (users.length === 0) {
        this.setState({
          loading: true,
        });
        const { auth, admin } = nextProps;
        if (auth && admin && admin.jwt) {
          const response = await this.getUsers(admin.jwt);
          this.setState({
            loading: false,
          });
          if (response) {
            this.setState({
              users: response.Items,
              results: response.Items,
            });
          }
        }
      }
    }
  }

  async componentDidMount() {
    const { users } = this.state;
    if (users.length === 0) {
      this.setState({
        loading: true,
      });
      const { auth, admin } = this.props;
      if (auth && admin && admin.jwt) {
        const response = await this.getUsers(admin.jwt);
        this.setState({
          loading: false,
        });
        if (response) {
          this.setState({
            users: response.Items,
            results: response.Items,
          });
        }
      }
    }
  }

  content() {
    const { results, filter, loading, column, direction } = this.state;
    if (loading) {
      return (
        <Dimmer active>
          <Loader>Cargando</Loader>
        </Dimmer>
      );
    } else if (results.length > 0) {
      return (
        <Table selectable sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'starred' ? direction : null}
                onClick={() => this.handleSort('starred')}
              >
                <Icon name="star"/>
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'name' ? direction : null}
                onClick={() => this.handleSort('name')}
              >
                Nombre
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => this.handleSort('email')}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'phone' ? direction : null}
                onClick={() => this.handleSort('phone')}
              >
                Teléfono
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'country' ? direction : null}
                onClick={() => this.handleSort('country')}
              >
                País
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'company' ? direction : null}
                onClick={() => this.handleSort('company')}
              >
                Compañía
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'role' ? direction : null}
                onClick={() => this.handleSort('role')}
              >
                Cargo
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'message' ? direction : null}
                onClick={() => this.handleSort('message')}
              >
                Mensaje
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'usesAWS' ? direction : null}
                onClick={() => this.handleSort('usesAWS')}
              >
                ¿Usa AWS?
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'hasWon' ? direction : null}
                onClick={() => this.handleSort('hasWon')}
              >
                Ganador
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'createdAt' ? direction : null}
                onClick={() => this.handleSort('createdAt')}
              >
                Creado
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'updatedAt' ? direction : null}
                onClick={() => this.handleSort('updatedAt')}
              >
                Actualizado
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results.map((result, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Rating
                    rating={result.starred || 0}
                    onRate={(e, { rating }) => this.handleRate(e, i, rating)}
                  />
                </Table.Cell>
                <Table.Cell>{result.name}</Table.Cell>
                <Table.Cell>{result.email}</Table.Cell>
                <Table.Cell>{result.phone}</Table.Cell>
                <Table.Cell>{result.country}</Table.Cell>
                <Table.Cell>{result.company}</Table.Cell>
                <Table.Cell>{result.role}</Table.Cell>
                <Table.Cell>{result.message || ''}</Table.Cell>
                <Table.Cell>{result.usesAWS ? 'Sí' : 'No'}</Table.Cell>
                <Table.Cell>{result.hasWon ? 'Sí' : 'No'}</Table.Cell>
                <Table.Cell>{result.createdAt || ''}</Table.Cell>
                <Table.Cell>{result.updatedAt || ''}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else if (filter) {
      return (
        <Message content="No hay resultados para tu filtro"/>
      );
    } else {
      return (
        <Message content="¡Todavía no se ha registrado ningún participante!"/>
      );
    }
  }

  render() {
    const { auth, admin } = this.props;
    const { results, filter } = this.state;

    if (auth && admin && admin.jwt) {
      return (
        <Container
          fluid
          style={{
            margin: '7.5em 0',
            padding: '0 1em',
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>Participantes</h2>
          <Segment
            basic
            style={{
              textAlign: 'left',
              padding: 0,
            }}
          >
            <Input
              floated="left"
              icon="search"
              placeholder="Filtrar"
              name="filter"
              value={filter}
              onChange={this.handleFilter}
            />
            <Button
              floated="right"
              icon="download"
              content="CSV"
              onClick={this.handleExport}
              disabled={!results.length}
            />
          </Segment>
          {this.content()}
        </Container>
      );
    } else {
      return this.state.error;
    }
  }

}

Users.propTypes = {
  authStateHandler: PropTypes.func,
  auth: PropTypes.bool,
  admin: PropTypes.object,
};

Users.defaultProps = {
  authStateHandler: ({ auth, admin }) => {
    this.props.auth = auth;
    this.props.admin = admin;
  },
  auth: false,
  admin: null,
};

export default Users;
