// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

// Semantic UI Components
import {
  Dimmer,
  Loader,
} from 'semantic-ui-react';

// Components
import App from './components/App';
import CacheBuster from './components/CacheBuster';

// Utils
import * as serviceWorker from './utils/serviceWorker';

class Root extends React.Component {
  render() {
    return (
      <Router>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) {
              return (
                <Dimmer active>
                  <Loader>Loading</Loader>
                </Dimmer>
              );
            }
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }
            return(
                <App/>
            );
          }}
        </CacheBuster>
      </Router>
    );
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
